



























import Vue from 'vue'
import {
  mapState,
  mapActions
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import PromocodeForm from './PromocodeForm.vue'
import PromocodesList from './PromocodesList.vue'
import { Promocode } from '@/store/modules/promocodes/types'
import { PAGINATION_LIMIT } from '@/common/constants'

export default Vue.extend({
  name: 'ArchivedPromocodes',

  components: {
    Loading,
    PromocodeForm,
    PromocodesList
  },

  props: {
    profileId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      offset: 0,
      limit: PAGINATION_LIMIT,
      editingPromocode: null as Promocode | null,
      loading: true,
      promocodes: [],
      fields: [
        {
          key: 'description',
          label: 'Название'
        },
        {
          key: 'type',
          label: 'Тип промокода'
        },
        {
          key: 'discount_amount',
          label: 'Размер скидки, KGS.'
        },
        {
          key: 'created_at',
          label: 'Дата создания'
        },
        {
          key: 'archived_at',
          label: 'Дата архивации'
        },
        {
          key: 'code',
          label: 'Промокод'
        }
      ]
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ])
  },

  mounted () {
    this.getPromocodes()
  },

  methods: {
    ...mapActions('promocodes', [
      'getProfilePromocodes'
    ]),

    async getPromocodes () {
      try {
        this.loading = true
        const res = await this.getProfilePromocodes({
          profileId: this.profileId || this.profile?.profile_data?.id || null,
          isArchived: true
        })
        this.promocodes = res
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    showPromocodeDetails (promocode: any) {
      this.editingPromocode = promocode
      this.$bvModal.show('modal-edit-promocode')
    }
  }
})
